import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { t } from "i18next";
import { v4 as uuid } from "uuid";

import { type LeaderboardKey } from "~/enums";

import { getLeaderboard, type GetLeaderboardResponse } from "./leaderboard.api";
import { type LeaderboardResponse, type LeaderboardPerson } from "./leaderboard.schema";
import { getBaseLeaderboardQueryKey } from "./utilities";

export { type LeaderboardResponse, type LeaderboardPerson } from "./leaderboard.schema";

const getQueryKey = (leaderboardKey: LeaderboardKey) => [...getBaseLeaderboardQueryKey(), leaderboardKey] as const;

const getBaseQueryOptions = <TData = GetLeaderboardResponse>(leaderboardKey: LeaderboardKey) =>
	queryOptions<GetLeaderboardResponse, Error, TData>({
		queryFn: () => getLeaderboard(leaderboardKey),
		queryKey: getQueryKey(leaderboardKey),
	});

export const MINIMUM_LEADERS = 3;

export const generatePlaceholderLeader = () => ({
	avatarUrl: null,
	id: uuid(),
	isCurrentUser: false,
	name: t("leaderboard.name-placeholder"),
	points: "-",
	position: "-",
});

const buildMinimumLeaderData = (data: LeaderboardResponse) =>
	data.length >= MINIMUM_LEADERS
		? data
		: data.concat(
				Array.from<LeaderboardPerson>({ length: MINIMUM_LEADERS - data.length }).map(generatePlaceholderLeader),
			);

const useLeaderboardSuspenseQuery = (leaderboardKey: LeaderboardKey) =>
	useSuspenseQuery({
		queryFn: () => getLeaderboard(leaderboardKey),
		queryKey: getQueryKey(leaderboardKey),
		select: (data) => {
			const sortedLeaderboard = structuredClone(data).sort((a, b) => Number(a.position) - Number(b.position));

			if (!sortedLeaderboard.length) {
				return {
					remainder: [],
					top: [],
					visible: false,
				};
			}

			return {
				remainder: buildMinimumLeaderData(sortedLeaderboard.slice(3, sortedLeaderboard.length)),
				top: buildMinimumLeaderData(sortedLeaderboard.slice(0, 3)),
				visible: true,
			};
		},
	});

export {
	getQueryKey as getLeaderboardQueryKey,
	getBaseQueryOptions as getLeaderboardBaseQueryOptions,
	useLeaderboardSuspenseQuery,
};
