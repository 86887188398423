import { type ConfiguredMiddleware, type Wretch, type WretchAddon } from "wretch";

export interface BearerAuthAddon {
	/**
	 * Appends a custom header to the request which the napier middleware stack will intercept and automatically cache the request.
	 *
	 * ```ts
	 * wretch("url1")
	 *   .addon(bearerAuthAddon)
	 *   .bearerAuth(getAuthToken)
	 *   .get()
	 *   .json()
	 * ```
	 */
	bearerAuth: <T extends BearerAuthAddon, C, R>(
		this: T & Wretch<T, C, R>,
		tokenGetter: (() => Promise<string> | string) | string,
	) => this;
}

const makeMiddlewareNew =
	(tokenGetter: string | (() => Promise<string> | string)): ConfiguredMiddleware =>
	(next) =>
	async (url, opts) => {
		const token = typeof tokenGetter === "function" ? await tokenGetter() : tokenGetter;

		opts.headers = {
			...opts.headers,
			Authorization: `Bearer ${token}`,
		};

		return next(url, opts);
	};

export const bearerAuthAddon: WretchAddon<BearerAuthAddon> = {
	wretch: {
		bearerAuth(tokenGetter) {
			/**
			 * This middleware is added to the start of the stack to ensure it is the first middleware to execute.
			 * This is so the authorisation header is present for any additional middleware that may need it
			 */
			return this.middlewares([makeMiddlewareNew(tokenGetter), ...this._middlewares], true);
		},
	},
};
