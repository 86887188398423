import { queryOptions, useSuspenseQuery, type UseSuspenseQueryOptions } from "@tanstack/react-query";

import { getBaseLearningQueryKey } from "../shared.schema";

import { getLearning, type GetLearningResponse } from "./learning.api";

const getLearningBaseQueryOptions = <TData = GetLearningResponse>() =>
	queryOptions<GetLearningResponse, Error, TData>({
		queryFn: getLearning,
		queryKey: getBaseLearningQueryKey(),
	});

const useLearningSuspenseQuery = <TData = GetLearningResponse>(
	options?: Pick<UseSuspenseQueryOptions<GetLearningResponse, Error, TData>, "select">,
) =>
	useSuspenseQuery({
		...options,
		...getLearningBaseQueryOptions<TData>(),
	});

export { getLearningBaseQueryOptions, useLearningSuspenseQuery };
