import { NapierBridgeCommon } from "@evotix/napier-ui-common-native-bridge";
import { createRouter, lazyRouteComponent, createRoute, redirect } from "@tanstack/react-router";

import { applicationContext } from "~/features/application/context";
import { queryClient } from "~/lib/queryClient";
import { hasAuthenticationCookie } from "~/utilities/has-authentication-cookie";

import { DefaultPendingComponent } from "./components/DefaultPendingComponent";
import { rootRoute } from "./root";
import { applicationLayout } from "./routes/application";
import {
	dashboardRoute,
	leaderboardDetailRoute,
	leaderboardRoute,
	settingsRoute,
	settingsIndexRoute,
	submitContentRoute,
	searchRoute,
	submitContentIndexRoute,
	leaderboardDetailIndexRoute,
	searchIndexRoute,
} from "./routes/global";
import {
	directLevelRoute,
	directLevelIndexRoute,
	directLevelMissionRoute,
	directLevelMissionIndexRoute,
	directLevelStageRoute,
	directMissionIndexRoute,
	directMissionRoute,
	directMissionStageRoute,
	directStageRoute,
	directStageIndexRoute,
	learningIndexRoute,
	learningRoute,
	levelIndexRoute,
	levelRoute,
	missionIndexRoute,
	missionRoute,
	pathwayIndexRoute,
	pathwayRoute,
	stageRoute,
	stageIndexRoute,
	directLevelStageIndexRoute,
	directMissionStageIndexRoute,
} from "./routes/learning";
import {
	legalLayout,
	privacyPolicyIndexRoute,
	privacyPolicyRoute,
	termsAndConditionsIndexRoute,
	termsAndConditionsRoute,
} from "./routes/legal";
import { privateRoute } from "./routes/private";
import {
	awardsIndexRoute,
	awardsRoute,
	interestsIndexRoute,
	interestsRoute,
	profileIndexRoute,
	profileRoute,
} from "./routes/profile";
import {
	publicRoute,
	signInRoute,
	signUpRoute,
	authenticationLayout,
	forgotPasswordRoute,
	resetPasswordRoute,
	signInIndexRoute,
	signUpIndexRoute,
	forgotPasswordIndexRoute,
	resetPasswordIndexRoute,
} from "./routes/public";
import {
	resourceRoute,
	resourcesCategoryRoute,
	resourceCategoriesRoute,
	directResourceRoute,
	directResourceIndexRoute,
	resourceIndexRoute,
	resourceCategoriesIndexRoute,
	resourcesCategoryIndexRoute,
	directResourceCategoryRoute,
	directResourceCategoryIndexRoute,
} from "./routes/resources";
import { tenantRoute } from "./routes/tenant";

export const indexRoute = createRoute({
	beforeLoad: async () => {
		if (applicationContext.isNative()) {
			await NapierBridgeCommon.getActiveTenant().then((serviceCode) => {
				if (serviceCode) {
					const tenant = serviceCode.split(":")[1];
					if (tenant) {
						throw redirect({
							params: {
								tenant,
							},
							to: tenantRoute.to,
						});
					}
				}
			});
		}
	},
	component: lazyRouteComponent(() => import("~/pages"), "IndexPage"),
	getParentRoute: () => rootRoute,
	path: "/",
});

export const routeTree = rootRoute.addChildren([
	indexRoute,
	tenantRoute.addChildren([
		publicRoute.addChildren([
			authenticationLayout.addChildren([
				signInRoute.addChildren([signInIndexRoute]),
				signUpRoute.addChildren([signUpIndexRoute]),
				forgotPasswordRoute.addChildren([forgotPasswordIndexRoute]),
				resetPasswordRoute.addChildren([resetPasswordIndexRoute]),
			]),
		]),
		privateRoute.addChildren([
			applicationLayout.addChildren([
				dashboardRoute,
				submitContentRoute.addChildren([submitContentIndexRoute]),
				leaderboardRoute.addChildren([leaderboardDetailRoute.addChildren([leaderboardDetailIndexRoute])]),
				settingsRoute.addChildren([settingsIndexRoute]),
				profileRoute.addChildren([
					profileIndexRoute,
					awardsRoute.addChildren([awardsIndexRoute]),
					interestsRoute.addChildren([interestsIndexRoute]),
				]),
				resourceCategoriesRoute.addChildren([
					resourceCategoriesIndexRoute,
					resourcesCategoryRoute.addChildren([
						resourcesCategoryIndexRoute,
						resourceRoute.addChildren([resourceIndexRoute]),
						directResourceRoute.addChildren([directResourceIndexRoute]),
					]),
					directResourceCategoryRoute.addChildren([directResourceCategoryIndexRoute]),
				]),
				learningRoute.addChildren([
					learningIndexRoute,
					pathwayRoute.addChildren([
						pathwayIndexRoute,
						levelRoute.addChildren([
							levelIndexRoute,
							missionRoute.addChildren([missionIndexRoute, stageRoute.addChildren([stageIndexRoute])]),
						]),
					]),
				]),
				directLevelRoute.addChildren([
					directLevelIndexRoute,
					directLevelMissionRoute.addChildren([
						directLevelMissionIndexRoute,
						directLevelStageRoute.addChildren([directLevelStageIndexRoute]),
					]),
				]),
				directMissionRoute.addChildren([
					directMissionIndexRoute,
					directMissionStageRoute.addChildren([directMissionStageIndexRoute]),
				]),
				directStageRoute.addChildren([directStageIndexRoute]),
				searchRoute.addChildren([searchIndexRoute]),
			]),
			legalLayout.addChildren([
				termsAndConditionsRoute.addChildren([termsAndConditionsIndexRoute]),
				privacyPolicyRoute.addChildren([privacyPolicyIndexRoute]),
			]),
		]),
	]),
]);

export const router = createRouter({
	context: {
		auth: {
			isAuthenticated: hasAuthenticationCookie,
		},
		legal: {
			acceptingPrivacyPolicy: false,
			acceptingTermsConditions: false,
		},
		queryClient,
	},
	defaultErrorComponent: lazyRouteComponent(
		() => import("./components/DefaultErrorComponent"),
		"DefaultErrorComponent",
	),
	defaultNotFoundComponent: lazyRouteComponent(() => import("~/pages/NotFound"), "NotFoundPage"),
	// We don't lazy load this as it's a critical component and we want to show it as soon as possible
	defaultPendingComponent: () => <DefaultPendingComponent />,
	defaultStructuralSharing: true,
	routeTree,
	trailingSlash: "preserve",
});

NapierBridgeCommon.addListener("appUrlOpen", async ({ url }) => {
	const urlObject = new URL(url);
	await router.navigate({ replace: true, to: `${urlObject.pathname}${urlObject.search}` });
});
