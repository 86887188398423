import { type ConnectionStatus, type Platform } from "@evotix/napier-ui-common-native-bridge";
import { isNative } from "@evotix/napier-ui-common-native-bridge/utilities";

class NotDefinedError extends Error {
	constructor(property: keyof IApplicationContext) {
		super(`[ApplicationContext]: ${property} not defined`);
		this.name = "NotDefinedError";
	}
}

const DEFAULT_CONNECTION_STATUS = { connected: false, connectionType: "none" } as const;

export type IApplicationContext = {
	platform: Platform;
	tenant: string | null;
	connectionStatus: ConnectionStatus;
};

export class ApplicationContext {
	#tenant: IApplicationContext["tenant"] | null;
	#connection: IApplicationContext["connectionStatus"];

	public platform: IApplicationContext["platform"];

	constructor() {
		this.platform = "web";
		this.#tenant = null;
		this.#connection = DEFAULT_CONNECTION_STATUS;
	}

	public reset() {
		this.platform = "web";
		this.#tenant = null;
	}

	public set tenant(tenant: string) {
		this.#tenant = tenant;
	}

	public get tenant() {
		if (!this.#tenant) {
			throw new NotDefinedError("tenant");
		}

		return this.#tenant;
	}

	/**
	 * A safe wrapper for getting the tenant that does not throw
	 * @returns {string | null}
	 * @example const tenant = applicationContext.getTenant();
	 */
	public getTenant() {
		try {
			return this.tenant;
		} catch {
			return null;
		}
	}

	public isNative() {
		return isNative();
	}

	public isWeb() {
		return !this.isNative();
	}

	public get connection() {
		return this.#connection;
	}

	public set connection(connection: ConnectionStatus) {
		this.#connection = connection;
	}
}

export const applicationContext = new ApplicationContext();
