import { type QueryClient } from "@tanstack/react-query";

import { LeaderboardKey } from "~/enums";

import { getActivitiesBaseQueryOptions } from "../api";
import { getCompletedLearningQueryOptions } from "../completed-learning/api";
import { getLeaderboardBaseQueryOptions } from "../leaderboard/api";

export const prefetchActivitiesData = async (queryClient: QueryClient) => {
	await Promise.allSettled([
		queryClient.prefetchQuery(getActivitiesBaseQueryOptions()),
		queryClient.prefetchQuery(getLeaderboardBaseQueryOptions(LeaderboardKey.MONTHLY)),
		queryClient.prefetchQuery(getLeaderboardBaseQueryOptions(LeaderboardKey.OVERALL)),
		queryClient.prefetchQuery(getCompletedLearningQueryOptions()),
	]);
};
