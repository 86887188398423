import { type InitialiseReturn, NapierBridgeCommon, NapierBridgeNative } from "@evotix/napier-ui-common-native-bridge";
import { setCookie, deleteCookie } from "@evotix/napier-ui-common-native-bridge/utilities";
import { jwtDecode, type JwtPayload } from "jwt-decode";

import { TOKEN_COOKIE_NAME } from "~/features/application/constants";
import { applicationContext } from "~/features/application/context";
import { getNativeHeartbeat } from "~/features/authentication/heartbeat/api";
import { getProfileBaseQueryOptions } from "~/features/profile/api";
import { router } from "~/features/routing";
import { queryClient } from "~/lib/queryClient";
import { getWindowHostDomain } from "~/utilities/get-window-host-domain";

type CustomJwtPayload = JwtPayload & {
	sub?: { uid: number };
};

export const initialiseNativeAppAuthentication = async (data: InitialiseReturn) => {
	if (data.platform !== "native" || !data.persistedData) {
		/**
		 * Do nothing if we're not running in a native environment
		 * If we don't have any persisted data, we can't do anything, the user will have to sign in again
		 */
		return;
	}

	const { token, tenant } = data.persistedData;

	if ((token && !tenant) || (!token && !tenant)) {
		/**
		 * We don't have a tenant associated on the bridge.
		 * In this instance we have to assume whatever user the bridge potentially has is not valid anymore and need to be clear out
		 */
		await NapierBridgeCommon.setActiveUser({ token: undefined, userId: null });
		await NapierBridgeNative.deactivateTenant();
		return;
	} else if (!token && tenant) {
		/**
		 * We can have a tenant from a previous authentication, but the browser still have a cookie present from that session.
		 * We need to ensure this cookie gets deleted, so that react app doesn't think the user is authenticated, when the bridge does not have a token/user
		 */
		applicationContext.tenant = tenant;
		await ensureCookieDeletion();
		return;
	}

	try {
		/**
		 * Not sure why typescript is complaining about the token and tenant still being able to be null, as we've checked for this above.
		 * Forcing the non-null assertion here as we know it's runtime safe
		 */
		const validTenant = tenant!;
		const validToken = token!;

		/**
		 * When offline the bridge will always return a success response
		 */
		const heartbeatResponse = await getNativeHeartbeat(validTenant, validToken, async (error) => {
			await ensureCookieDeletion();

			return error;
		});

		if (!heartbeatResponse.success) {
			/**
			 * The request was unsuccessful, so we assume the user is not authenticated
			 */
			await ensureCookieDeletion();
			return;
		}

		// We don't set an expiry here, so the cookie is set as a session cookie, this enables us to have a cookie that works whilst offline
		await setCookie(TOKEN_COOKIE_NAME, validToken, { domain: `.${getWindowHostDomain()}` });

		// We set the tenant here so that it's available for the profile fetch
		applicationContext.tenant = validTenant;

		// Get the user profile to set the active user in the bridge - this ensure the FE and bridge are in sync
		const profile = await queryClient.fetchQuery(getProfileBaseQueryOptions());
		await NapierBridgeCommon.setActiveUser({ token: validToken, userId: profile.id.toString() });
	} catch {
		// The request errored, so we assume the user is not authenticated
		await ensureCookieDeletion();
	}
};

export const initialiseNativeAppSSOAuthentication = async (_url: string, cookies: Record<string, string>) => {
	const token = cookies[TOKEN_COOKIE_NAME];

	if (!token) {
		return;
	}

	try {
		const { sub } = jwtDecode<CustomJwtPayload>(token);

		// We don't set an expiry here, so the cookie is set as a session cookie, this enables us to have a cookie that works whilst offline
		await setCookie(TOKEN_COOKIE_NAME, token, { domain: `.${getWindowHostDomain()}` });

		await NapierBridgeCommon.setActiveUser({ token, userId: sub!.uid.toString() });

		await router.invalidate();
	} catch {
		// Swallow the error, as we're not able to decode the token, there's nothing we can do, at this point, we have to assume the user is not authenticated.
		await ensureCookieDeletion();
	}
};

export const ensureCookieDeletion = async () => {
	for await (const domain of [window.location.hostname, `.${getWindowHostDomain()}`]) {
		for await (const path of ["/", `/${applicationContext.getTenant()}`]) {
			await deleteCookie(TOKEN_COOKIE_NAME, { domain, path });
		}
	}

	await NapierBridgeCommon.setActiveUser({ token: undefined, userId: null });
};
